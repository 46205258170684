/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {BaseController} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/controller-factory/BaseController';
import {ControllerParams} from '@wix/yoshi-flow-editor';
import {GalleryStore} from './GalleryStore';
import {IGalleryControllerConfig, VeloInputs, VeloInputsOnItemSelected} from '../types/galleryTypes';
import {appClient, Scope} from '@wix/app-settings-client';
import {APP_SETTINGS_CDN} from '@wix/wixstores-client-core/dist/es/src/constants';
import {createSlotVeloAPIFactory} from '@wix/widget-plugins-ooi/velo';
import _ from 'lodash';
import {QueryParamsService} from '@wix/wixstores-client-storefront-sdk';
import {Experiments, WixCustomHeaders} from '../constants';

type ResolveFn<T> = (value: T | PromiseLike<T>) => void;

export class GalleryController extends BaseController {
  private readonly compId: string;
  private galleryStore: GalleryStore;
  private veloInputs: VeloInputs;
  private readonly waitUntilReady: Promise<void>;
  private onReadyResolver: ResolveFn<void>;
  private initializeCallback: Function;
  private readonly queryParamsService: QueryParamsService;
  private rerenderUrlHeaders: {lang: string; currency: string};
  private readonly slotAPIFactory: ReturnType<typeof createSlotVeloAPIFactory>;
  private currentQueryParams: {[key: string]: string};
  private config: IGalleryControllerConfig;
  private readonly componentId: string;

  private async initializeVeloDataAfterStoreCreated() {
    await this.waitUntilReady;
    this.galleryStore.setVeloInputs(this.veloInputs);
    return this.galleryStore.setInitialState();
  }

  public setVeloInputs = (veloInputs: VeloInputs) => {
    this.veloInputs = {
      ...this.veloInputs,
      ...veloInputs,
    };
  };

  public exports = () => {
    return {
      setCollection: async (collectionId: string): Promise<void> => {
        this.setVeloInputs({collectionId, productIds: undefined});
        if (this.galleryStore) {
          return this.initializeVeloDataAfterStoreCreated();
        }
      },
      setProducts: async (productIds: string[]) => {
        this.setVeloInputs({productIds, collectionId: undefined});
        if (this.galleryStore) {
          return this.initializeVeloDataAfterStoreCreated();
        }
      },
      onInitialize: (cb: Function) => {
        this.initializeCallback = cb;
      },
      onItemSelected: (
        callBack: VeloInputsOnItemSelected['callBack'],
        options: VeloInputsOnItemSelected['options'] = {preventNavigation: false}
      ) => {
        //TODO:Zeev: this is part of Categories POC
        this.setVeloInputs({onItemSelected: {callBack, options}});
        if (this.galleryStore) {
          return this.initializeVeloDataAfterStoreCreated();
        }
      },
    };
  };

  public onConfigUpdate = async (config: IGalleryControllerConfig) => {
    this.config = _.clone(config);
    await this.galleryStore.updateState(this.config, {APP: {}, COMPONENT: {}});
  };

  /* istanbul ignore next: for PR only, will be tested before merge */
  public onAppSettingsUpdate = (updates: {[key: string]: any}) => {
    if (
      this.siteStore.experiments.enabled(Experiments.EditorGalleryOOI) &&
      updates.scope === Scope.COMPONENT &&
      updates.source === 'app-settings'
    ) {
      void this.galleryStore.updateState(this.config, {
        APP: undefined,
        COMPONENT: {},
        appSettings: updates.payload,
      });
    }
  };

  public getFreeTexts = (): string[] => {
    return [];
  };

  constructor(controllerParams: ControllerParams) {
    super(controllerParams);
    this.config = _.clone(controllerParams.controllerConfig.config);
    this.compId = controllerParams.controllerConfig.compId;
    this.componentId = controllerParams.controllerConfig.type;
    this.queryParamsService = new QueryParamsService<typeof this.siteStore>(this.siteStore);
    this.rerenderUrlHeaders = {
      [WixCustomHeaders.Lang]: this.queryParamsService.getQueryParam(WixCustomHeaders.Lang),
      [WixCustomHeaders.Currency]: this.queryParamsService.getQueryParam(WixCustomHeaders.Currency),
    };
    const isEditor = typeof window !== 'undefined' && window.Wix;
    this.slotAPIFactory = createSlotVeloAPIFactory(controllerParams.controllerConfig);

    /* istanbul ignore else: todo(flow-editor): test */
    if (isEditor) {
      this.listenToAppSettingsUpdate();
    }

    this.waitUntilReady = new Promise((resolve) => {
      this.onReadyResolver = resolve;
    });
  }

  private listenToAppSettingsUpdate() {
    const appSettingsClient = appClient({scope: Scope.COMPONENT, cdnUrl: APP_SETTINGS_CDN});
    appSettingsClient.onChange((pb) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.galleryStore.updateState(this.config, {APP: undefined, COMPONENT: {}, appSettings: pb});
    });
  }

  /* eslint-disable sonarjs/cognitive-complexity */
  public readonly load = async () => {
    if (this.initializeCallback) {
      await Promise.resolve(this.initializeCallback()).catch(this.flowAPI.reportError);
    }

    const newQueryParams = this.siteStore.location.query;
    if (!this.currentQueryParams) {
      this.currentQueryParams = {...newQueryParams};
    }

    if (this.galleryStore) {
      const {page: lastPage, ...lastQueryParams} = this.currentQueryParams;
      const {page: currentPage, ...currentQueryParams} = newQueryParams;
      this.currentQueryParams = {...newQueryParams};
      if (_.isEqual(lastQueryParams, currentQueryParams)) {
        if (lastPage !== currentPage) {
          this.galleryStore.updateAppOnQueryParamsChange({isPageUpdated: true});
        }
        return;
      }
    }

    /* istanbul ignore next: cannot test */
    if (this.galleryStore && this.siteStore.experiments.enabled(Experiments.FixGalleryRenderingWhenUrlChanges)) {
      const currentLang = this.queryParamsService.getQueryParam(WixCustomHeaders.Lang);
      const currentCurrency = this.queryParamsService.getQueryParam(WixCustomHeaders.Currency);
      const {currency, lang} = this.rerenderUrlHeaders;
      if (currency === currentCurrency && lang === currentLang) {
        return;
      } else {
        this.rerenderUrlHeaders = {
          [WixCustomHeaders.Lang]: currentLang,
          [WixCustomHeaders.Currency]: currentCurrency,
        };
      }
    }

    this.galleryStore = new GalleryStore(
      this.config,
      this.setProps.bind(this),
      this.siteStore,
      this.compId,
      this.componentId,
      this.flowAPI.reportError,
      this.slotAPIFactory,
      this.veloInputs
    );

    return this.galleryStore
      .setInitialState()
      .then(() => this.onReadyResolver())
      .catch(this.flowAPI.reportError);
  };

  public readonly init = async () => {
    await this.load();
  };
}
